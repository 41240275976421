import React, { FC, useRef } from 'react';
import moment from 'moment';
import { Colors, FontFamilies, FontWeights } from 'core/CssVariables';
import { Icon } from '../Icon';
import { Col, Row } from '../Grid';
import { TextIcon } from '../TextIcon';
import { SecondaryText } from '../Typography';
import { TIconNames } from '../Icon/Icon.type';
import { SFileWrapper } from './File.style';
import { TFileData, TFileProps } from './File.type';
import PreviewFileModal from './PreviewFileModal';

export const File: FC<TFileProps> = ({
  filesCount,
  onDelete = () => {
    /* do nothing */
  },
  onView = () => {
    /* do nothing */
  },
  file,
  fileLabel,
  hideDelete = false,
  onDownload,
  canEdit,
  textFileContent,
  className = '',
}) => {
  const previewFileModalRef = useRef<{
    open: (previewFile: TFileData) => void;
  }>({
    open: () => {
      /* do nothing */
    },
  });

  const handleFileView = () => {
    if (!filesCount && file?.name) {
      return previewFileModalRef.current.open(file);
    }
    if (canEdit || filesCount) {
      return onView();
    }
    return null;
  };

  return (
    <>
      <SFileWrapper
        align="middle"
        className={`mt-3 mb-3 ${className}`}
        justify="space-between"
      >
        <Row
          wrap={false}
          align="middle"
          justify="space-between"
          className="w-100"
        >
          <Col>
            {!filesCount && (file?.createdDate || file?.createdBy) && (
              <SecondaryText
                fontLevel={6}
                fontFamily={FontFamilies.FontSecondary}
                className="mb-1"
              >
                { file?.createdBy && `Uploaded by ${file?.createdBy}: `}
                { file?.createdDate &&moment(file?.createdDate).format('MM/DD/YYYY')}
              </SecondaryText>
            )}

            <TextIcon
              fontLevel={5}
              fontWeight={FontWeights.Medium}
              labelColor={Colors.DarkBlue}
              icon={TIconNames.FILE_ICON}
              className="file-name"
              onClick={() => handleFileView()}
              label={
                !filesCount
                  ? file?.name || 'Upload documents'
                  : fileLabel || `Included ${filesCount} doc(s)`
              }
            />
          </Col>
          <Col>
            <Row wrap={false} align="middle">
              {filesCount || file ? (
                <>
                  <Icon
                    icon={TIconNames.DOWNLOAD}
                    size={20}
                    color={Colors.DarkGrey}
                    className="mr-3 ml-3 pointer"
                    onClick={() => onDownload()}
                  />
                  {!hideDelete && canEdit && (
                    <Icon
                      icon={TIconNames.DELETE}
                      color={Colors.DarkGrey}
                      size={20}
                      className="pointer"
                      onClick={() => onDelete()}
                    />
                  )}
                </>
              ) : (
                <>
                  {canEdit && (
                    <Icon
                      icon={TIconNames.UPLOAD}
                      size={14}
                      color={Colors.DarkGrey}
                      className="mr-3 ml-3 pointer"
                      onClick={() => onView()}
                    />
                  )}
                </>
              )}
            </Row>
          </Col>
        </Row>
      </SFileWrapper>
      <PreviewFileModal
        ref={previewFileModalRef}
        textFileContent={textFileContent}
      />
    </>
  );
};

export default File;
