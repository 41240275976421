import React, { FC } from 'react';
import { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import { EFileTypes, TFileViewerProps } from './FileViewer.types';
import { SDocViewer } from './FilePreview.styles';

const FilePreview: FC<TFileViewerProps> = ({
  fileUrl,
  extension,
  textFileContent,
}) => {
  const fileExtension = extension.substring(extension.lastIndexOf('.') + 1);
  const fileType = EFileTypes[fileExtension as keyof typeof EFileTypes];

  if (!fileType) {
    return <p>Unsupported file type: {fileExtension}</p>;
  }

  if (fileType === 'txt' && textFileContent) {
    return <pre>{textFileContent}</pre>;
  }

  return (
    <SDocViewer
      pluginRenderers={DocViewerRenderers}
      config={{
        header: {
          disableHeader: true,
          disableFileName: false,
          retainURLParams: false,
        },
      }}
      documents={[{ uri: fileUrl, fileType }]}
    />
  );
};

export { FilePreview };
