import React, { FC } from 'react';
import { FontFamilies } from 'core/CssVariables';
import { Col, Row } from '../../../Grid';
import { Title } from '../../../Typography';
import { Button } from '../../../Button';
import { TPageHeaderProps } from '../../PageWrapper.type';
import SPageHeader from './PageHeader.style';

const PageHeader: FC<TPageHeaderProps> = ({
  hideActions,
  title,
  subTitle,
  fontLevel,
  actionTitle,
  onActionClick,
  actions,
  leftSidedActions,
  pageHeaderClassName,
}) => {
  return (
    <SPageHeader
      className={pageHeaderClassName}
      align="middle"
      gutter={[16, 16]}
    >
      <Col {...(hideActions && { xs: 0, md: 8 })}>
        <Row gutter={[16, 16]} className="actions">
          {title && (
            <Col>
              <Title
                fontLevel={fontLevel || 2}
                fontFamily={FontFamilies.FontSecondary}
                className="page-title"
              >
                {title}
              </Title>
            </Col>
          )}
          {leftSidedActions && (
            <Col className="actions-item">{leftSidedActions}</Col>
          )}
        </Row>
      </Col>
      <Col {...(hideActions && { xs: 0, md: 8 })}>{!!subTitle && subTitle}</Col>
      <Col flex={1} {...(hideActions && { xs: 0, md: 16 })}>
        <Row justify="end" className="actions">
          {actions && <Col className="actions-item">{actions}</Col>}
          {actionTitle && (
            <Col className="actions-item">
              <Button
                type="primary"
                className="action-button"
                {...(onActionClick && { onClick: onActionClick })}
              >
                {actionTitle}
              </Button>
            </Col>
          )}
        </Row>
      </Col>
    </SPageHeader>
  );
};
export default PageHeader;
