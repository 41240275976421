import React from 'react';
import notification from 'antd/es/notification';
import { Colors } from 'core/CssVariables';
import { Icon } from '../Icon';
import { TIconNames } from '../Icon/Icon.type';
import {
  TNotificationTypeParams,
  TToastProps,
  NotificationType,
} from './Notification.type';

const NotificationMapper: {
  [key in NotificationType]: TNotificationTypeParams;
} = {
  error: {
    bgColor: Colors.ErrorLight,
    borderColor: Colors.ErrorColor,
    icon: {
      name: TIconNames.WARNING_OUTLINED,
      color: Colors.ErrorColor,
    },
  },
  info: {
    bgColor: Colors.LightBlue,
    borderColor: Colors.Blue,
    icon: {
      name: TIconNames.INFO,
      color: Colors.Blue,
    },
  },
  success: {
    bgColor: Colors.SuccessLight,
    borderColor: Colors.Green,
    icon: {
      name: TIconNames.CHECK_OUTLINED,
      color: Colors.Green,
    },
  },
  warning: {
    bgColor: Colors.WarningColorHover,
    borderColor: Colors.WarningColor,
    icon: {
      name: TIconNames.WARNING_OUTLINED,
      color: Colors.WarningColor,
    },
  },
};

const toast = ({ type, title, description, placement, ...props }: TToastProps) => {
  notification.open({
    message: title,
    description,
    style: {
      backgroundColor: NotificationMapper[type].bgColor,
      borderColor: NotificationMapper[type].borderColor,
      zIndex: 9999,
    },
    icon: (
      <Icon
        icon={NotificationMapper[type].icon.name}
        color={NotificationMapper[type].icon.color}
        size={20}
      />
    ),
    closeIcon: <Icon icon={TIconNames.CLOSE_CIRCLE} />,
    placement,
    ...props
  });
};

const toastDestroy = (key?: string) => notification.destroy();
const toastClose = (key: string) => notification.close(key);

export { toast, toastDestroy, toastClose };