import React from 'react';
import { Colors } from 'core/CssVariables';
import { TIconNames } from '../../Icon/Icon.type';
import { Icon } from '../../Icon';
import { SProgressDotCircle } from './Stepper.styles';
import { TProgressDotStepDataProps } from './Stepper.types';

export const iconMapper: { [key in string]: JSX.Element } = {
  process: <SProgressDotCircle $color={Colors.PrimaryColor} />,
  wait: <SProgressDotCircle $color={Colors.LightGrey} />,
  finish: <Icon icon={TIconNames.CHECK_CIRCLE_FILLED_ICON} size={20} />,
  error: <SProgressDotCircle $color={Colors.ErrorColor} />,
};

export function getCustomDot(
  index: number,
  stepData: TProgressDotStepDataProps
): React.ReactNode {
  const { status } = stepData;
  return iconMapper[status];
}
