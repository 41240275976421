import React, { FC } from 'react';
import { Col } from '../Grid';
import { Stepper } from './Stepper';
import StepperContent from './StepperContent';
import { SStepWrapper } from './Steps.styles';
import { TStepsProps } from './Steps.types';

const Steps: FC<TStepsProps> = ({
  stepsData,
  loading,
  children,
  onNextClick = () => {
    // do nothing
  },
  onPreviousClick = () => {
    // do nothing
  },
  onSubmit,
  submitButtonText,
  currentStep,
  ...rest
}) => {
  return (
    <SStepWrapper wrap={false} gutter={[16, 16]}>
      <Col sm={6}>
        <Stepper stepsData={stepsData} currentStep={currentStep} {...rest}>
          {children}
        </Stepper>
      </Col>
      <Col sm={18}>
        <StepperContent
          currentStep={currentStep}
          stepsData={stepsData}
          loading={loading}
          onSubmit={onSubmit}
          submitButtonText={submitButtonText}
          onPreviousClick={onPreviousClick}
          onNextClick={onNextClick}
        />
      </Col>
    </SStepWrapper>
  );
};

export { Steps };
