import React from 'react';
import { Colors, FontWeights } from 'core/CssVariables';
import { Col } from '../../Grid';
import { Icon } from '../../Icon';
import { Text } from '../../Typography';
import { TIconNames } from '../../Icon/Icon.type';
import { SRow, SCol } from './EditableTextWithModal.style';
import { TEditableTextWithModalProps } from '../EditableFields.type';

export const EditableTextWithModal = ({
  label,
  canEdit,
  onEdit,
  icon,
}: TEditableTextWithModalProps) => {
  return (
    <SRow
      align="middle"
      className={`my-2 ${canEdit ? 'pointer' : ''}`}
      onClick={() => canEdit && onEdit()}
      wrap={false}
    >
      <Col>
        {icon && (
          <Icon
            icon={icon}
            color={Colors.DarkGrey}
            size={16}
            className="mr-2"
          />
        )}
      </Col>
      <SCol>
        <Text
          fontWeight={FontWeights.Medium}
          color={Colors.DarkGrey}
          fontLevel={5}
        >
          {label}
        </Text>
      </SCol>
      <Col>
        {canEdit && (
          <Icon
            icon={TIconNames.EDIT}
            color={Colors.Blue}
            size={16}
            className="ml-2 pointer edit-icon"
          />
        )}
      </Col>
    </SRow>
  );
};

export default EditableTextWithModal;
