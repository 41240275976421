import { TFile } from '../../types/generalTypes';

export type TImageUploaderSquareProps = {
    id?: string;
    url?: string | null;
    width?: number;
    height?: number;
    disabled?: boolean;
    isLoading?: boolean;
    uploadedImage?: TFile;
    onChange?: (data: TFile) => void;
    uploadFile?: (formData: FormData) => void;
    uploadTitle?: string;
    preview?: boolean;
};

export enum EUploaderErrorMessage {
    SIZE_ERROR = 'Please upload images up to 10MB.',
    FORMAT_ERROR = 'Please upload JPEG or PNG files.',
}
