import styled from 'styled-components';
import { Colors } from 'core/CssVariables';
import { TImageUploaderSquareProps } from './ImageUploaderSquare.type';

export const SImageUploaderSquare = styled.div<TImageUploaderSquareProps>`
  && {
    position: relative;
    .upload-wrapper {
      width: 100%;
      display: inline-flex;
      align-items: center;
    }

    .preview-wrapper {
      width: ${({ width }) => width || 340}px;
      height: ${({ height }) => height || 189}px;
      border-radius: 8px;
      position: absolute;
      top: 0;

      .ant-image-mask {
        border-radius: 8px;
      }
    }

    &&&& {
      .uploader-error {
        display: block;
        min-height: 0;
        color: ${Colors.ErrorColor};

        :hover {
          background-color: ${Colors.BackgroundColor};
        }
      }
    }

    .upload-btn {
      width: ${({ width }) => width || 340}px;
      height: ${({ height }) => height || 189}px;
      border-radius: 8px;
      position: relative;
      background-color: ${Colors.BackgroundColor};

      &.show-border {
        border: 2px dashed ${Colors.PrimaryColor};
      }

      .upload-block {
        position: absolute;
        text-align: center;
        justify-content: center;
        align-items: center;
        top: 50%;
        left: 56%;
        transform: translate(-53%, -50%);
        pointer-events: none;
        .upload-icon-wrapper {
          display: flex;
          width: 64px;
          height: 64px;
          border-radius: 50%;
          justify-content: center;
          align-items: center;
          background-color: ${Colors.PrimaryColor};
        }
        
      }

      .camera-icon {
        position: absolute;
        display: flex;
        left: 8px;
        bottom: 8px;
        width: 32px;
        height: 32px;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background-color: ${Colors.LightGrey};
        z-index: 9;
      }

      input {
        display: none;
      }
    }

    .upload-image {
      width: ${({ width }) => width || 340}px;
      height: ${({ height }) => height || 189}px;
      position: absolute;
      inset: 0 0 0 0;
      object-fit: cover;
      border-radius: 8px;
      pointer-events: none;
      max-width: 100%;

      &:not([src]) {
        display: none;
      }
    }

    .upload-text {
      white-space: nowrap;
      margin-left: 12px;
    }
  }
`;
  