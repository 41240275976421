import styled from 'styled-components';
import { Row, Col } from '../../Grid';

export const SRow = styled(Row)`
  && {
    .edit-icon {
      visibility: hidden;
    }
    &:hover {
      .edit-icon {
        visibility: visible;
      }
    }
  }
`;

export const SCol = styled(Col)`
  margin: 0 8px;
`;
