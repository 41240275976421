import { Colors, FontSizes } from 'core/CssVariables';
import styled from 'styled-components';
import { CardSection } from '../CardSection';
import { TSInfoBlockProps } from './InfoBlock.types';

export const SInfoBlockWrapper = styled(CardSection)<TSInfoBlockProps>`
  && {
    width: ${({ $width }) => `${$width}px` || '100%'};
    height: ${({ $height }) => $height || 'auto'};
    padding: 8px 4px;
    border: 1px solid ${Colors.DisabledColor};
    color: ${Colors.DarkGrey};
    font-size: ${FontSizes.FontSM}px;
    text-align: ${(props) => (props.$centered ? 'center' : 'left')};
  }
`;
