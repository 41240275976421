import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import { Colors } from 'core/CssVariables';
import { Row, Col } from '../Grid';
import { UploaderContent } from './components/UploaderContent';
import { TUploaderProps } from './Uploader.type';
import { TFileUI } from '../../types/generalTypes';
import { fileTypesMapper, uploadItemByFileType } from './Uploader.data';
import { UploaderWrapper } from './Uploader.style';
import { ImageItem } from './components/ImageItem';
import { VideoItem } from './components/VideoItem';
import { DocumentItem } from './components/DocumentItem';


const Uploader = ({
  accept,
  isLoading,
  dropFileTitle,
  maxSize,
  onUpload = () => {
    /* do nothing */
  },
  files = [],
  onDelete = () => {
    /* do nothing */
  },
  multiple = true,
  readOnly = false,
  color = 'grey'
}: TUploaderProps) => {
  const [uploadTitle, setUploadTitle] = useState('');
  const [isInvalid, setIsInvalid] = useState(false);
  const onUploadFile = async (acceptedFiles: File[]) => {
    await onUpload(acceptedFiles);
  };

  const onDrop = useCallback(
    async (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      await onUploadFile(acceptedFiles);
      if (fileRejections?.length === 1 && !acceptedFiles.length) {
        setIsInvalid(true);
        fileRejections.forEach((file) => {
          file.errors.forEach((err) => {
            if (err.code === 'file-too-large') {
              setUploadTitle(`File type should be less than ${maxSize}mb`);
            }

            if (err.code === 'file-invalid-type') {
              setUploadTitle('File type is invalid');
            }
          });
        });
        return;
      }
      setUploadTitle(
        `Accepted ${acceptedFiles.length}, rejected ${fileRejections.length}`
      );
      setIsInvalid(false);
    },
    [files]
  );

  useEffect(() => {
    if (files.length) {
      return;
    }
    setUploadTitle('');
  }, [files]);

  const onDeleteClick = (file: TFileUI) => {
    onDelete(file);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: accept || {
      '*': [],
    },
    maxSize: (maxSize || 5) * 1000000,
    disabled: isLoading,
    multiple,
  });

  const dropZoneSpecialStyle = useMemo(
    () => ({
      ...(isDragActive ? { background: `${Colors.BackgroundColor}` } : {}),
    }),
    [isDragActive]
  );
  return (
    <>
      {!readOnly && (
        <UploaderWrapper
          className="dropzone"
          color={color}
          {...getRootProps({ dropZoneSpecialStyle })}
        >
          <input {...getInputProps()} />
          <UploaderContent
            dropFileTitle={dropFileTitle}
            title={uploadTitle}
            invalid={isInvalid}
          />
        </UploaderWrapper>
      )}
      <Row gutter={[12, 12]} className="mt-6">
        {files.map((file) => {
          const Component = uploadItemByFileType[file.type];
          return (
            <Col>
              <Component
                file={file}
                onDelete={() => onDeleteClick(file)}
                readOnly={readOnly}
                key={file.name}
              />
            </Col>
          );
        })}
      </Row>
    </>
  );
};

export {
  Uploader,
  UploaderContent,
  ImageItem,
  VideoItem,
  DocumentItem,
  fileTypesMapper,
};
