import React, { FC } from 'react';
import { Colors } from 'core/CssVariables';
import Space from 'antd/es/space';
import { TIconNames } from '../Icon/Icon.type';
import { Icon } from '../Icon';
import { TCollapseProps } from './Collapse.types';
import { SCollapse, SPanel } from './Collapse.styles';

const Collapse: FC<TCollapseProps> = ({
  expandIconPosition = 'end',
  collapsible = 'header',
  color,
  collapseData,
  ...props
}) => {
  return (
    <Space direction="vertical" className="w-100">
      {collapseData.map((item) => (
        <SCollapse
          $color={color}
          key={item.key}
          expandIcon={({ isActive }) => (
            <Icon
              className={`${isActive ? 'active' : ''} pointer`}
              color={Colors.DarkGrey}
              icon={TIconNames.ARROW_FILLED}
              size={8}
            />
          )}
          expandIconPosition={expandIconPosition}
          collapsible={collapsible}
          {...props}
        >
          <SPanel
            className={item.content ? '' : 'no-body-content'}
            header={item.header}
            showArrow={item.showArrow}
            key={item.key}
          >
            {item.content}
          </SPanel>
        </SCollapse>
      ))}
    </Space>
  );
};

export { Collapse };
