import styled, { css } from 'styled-components';
import { Colors, FontSizes, FontWeights } from 'core/CssVariables';
import Button from 'antd/es/button';
import Group from 'antd/es/button/button-group';
import * as React from 'react';
import { ButtonProps } from 'antd/es/button/button';
import { TButtonProps, TButtonStyles, EButtonColors } from './Button.type';

export const btnStyles: TButtonStyles = {
  [EButtonColors.Primary]: {
    color: Colors.Black,
    bgColor: Colors.PrimaryColor,
    hoverColor: Colors.PrimaryColorHover,
  },
  [EButtonColors.Default]: {
    color: Colors.Black,
    bgColor: Colors.DisabledColor,
    hoverColor: Colors.DisabledColor,
  },
  [EButtonColors.Black]: {
    color: Colors.White,
    bgColor: Colors.Black,
    hoverColor: Colors.Black,
  },
  [EButtonColors.Info]: {
    color: Colors.White,
    bgColor: Colors.Blue,
    hoverColor: Colors.BlueHover,
  },
  [EButtonColors.Success]: {
    color: Colors.White,
    bgColor: Colors.SuccessColor,
    hoverColor: Colors.SuccessColorHover,
  },
  [EButtonColors.Warning]: {
    color: Colors.White,
    bgColor: Colors.WarningColor,
    hoverColor: Colors.WarningColorHover,
  },
  [EButtonColors.Danger]: {
    color: Colors.White,
    bgColor: Colors.ErrorColor,
    hoverColor: Colors.ErrorColorHover,
  },
};

const createButtonStyles = (
  color: EButtonColors,
  { width, height, $outlined: outlined, shape, size, type }: TButtonProps
) => css`
  && {
    display: inline-flex;
    align-items: center;
    text-shadow: none;
    box-shadow: none;
    justify-content: center;
    font-weight: 500;
    padding: ${size === 'small' ? '4px 12px' : '8px 24px'};
    background-color: ${outlined || type === 'text'
      ? 'transparent'
      : btnStyles[color].bgColor};
    color: ${(outlined &&
      ![EButtonColors.Primary, EButtonColors.Default].includes(color)) ||
    type === 'text'
      ? btnStyles[color].bgColor
      : btnStyles[color].color};
    border-color: ${outlined ? btnStyles[color].bgColor : 'transparent'};

    &:hover,
    &:active,
    &:focus {
      .icon {
        path {
          fill: ${outlined || type === 'text'
            ? btnStyles[color].hoverColor
            : Colors.White};
        }
      }

      background-color: ${outlined || type === 'text'
        ? 'transparent'
        : btnStyles[color].hoverColor};
      color: ${(outlined &&
        ![EButtonColors.Primary, EButtonColors.Default].includes(color)) ||
      type === 'text'
        ? btnStyles[color].hoverColor
        : btnStyles[color].color};
      border-color: ${outlined ? btnStyles[color].hoverColor : 'transparent'};
    }

    &:disabled,
    &:hover:disabled {
      .icon {
        path {
          fill: ${outlined || type === 'text'
            ? `${btnStyles[color].bgColor}4D`
            : Colors.White};
        }
      }

      cursor: default;
      background-color: ${outlined || type === 'text'
        ? Colors.Transparent
        : btnStyles[color].bgColor};
      color: ${outlined || type === 'text'
        ? btnStyles[color].bgColor
        : btnStyles[color].color};
      opacity: 0.3;
      border-color: ${outlined ? btnStyles[color].bgColor : 'transparent'};
    }

    .icon {
      margin-right: 8px;
      margin-bottom: 1px;

      path {
        transition: fill 0.3s ease;
        fill: ${outlined ? btnStyles[color].bgColor : Colors.White};
      }
    }

    ${() =>
      shape === 'circle' &&
      css`
        display: flex;
        justify-content: space-around;
        width: ${width ? `${width}px` : 'auto'};
        min-width: ${width ? `${width}px` : 'auto'};
        height: ${height || width ? `${height || width}px` : 'auto'};

        .icon {
          margin-right: 0;
        }
      `}

    ${() =>
      type === 'text' &&
      css`
        padding: 0;
        height: auto;
        border: none;
        color: ${color === EButtonColors.Default ? Colors.Black : ''};

        &:hover {
          color: ${color === EButtonColors.Default ? Colors.Black : ''};
        }

        &:not([disabled]) {
          &:hover {
            > span {
              text-decoration: underline;
            }
          }
        }

        ${size === 'small' &&
        css`
          font-size: ${FontSizes.FontXS}px;
          font-weight: ${FontWeights.Regular};
        `}
        .icon {
          margin-right: 8px;
          height: 16px;
          width: 16px;

          path {
            fill: ${btnStyles[color].bgColor};
          }
        }
      `}
  }
`;

interface CompoundedComponent
  extends React.ForwardRefExoticComponent<
    ButtonProps & React.RefAttributes<HTMLElement>
  > {
  Group: typeof Group;
  __ANT_BUTTON: boolean;
}

// without CompoundedComponent we get build error
// semantic error TS4023: Exported variable 'SButton' has or is using name 'CompoundedComponent' from external module "/home/gev/Code/ui-kit-opp/node_modules/antd/es/button/button" but cannot be named.
// I'm almost sertan that this is build error
// because of time issues I'm going to use any as type to pass this error.
// after release, we can start working on it

const SButton = styled<CompoundedComponent>(Button)`
  ${(props: TButtonProps) => {
    switch (props.type) {
      case 'default':
        return createButtonStyles(EButtonColors.Default, props);
      case 'primary':
      case 'text':
        return createButtonStyles(props.color || EButtonColors.Primary, props);
      default:
        return createButtonStyles(EButtonColors.Primary, props);
    }
  }}
`;

export default SButton;
