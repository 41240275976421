import React, { useState } from 'react';
import { Colors } from 'core/CssVariables';
import { Button } from '../../../Button';
import { EButtonTypes } from '../../../Button/Button.type';
import { Icon } from '../../../Icon';
import { TIconNames } from '../../../Icon/Icon.type';
import { Modal } from '../../../Modal';
import { ModalWidthEnum } from '../../../Modal/Modal.type';
import { TButtonDropdownProps } from '../../ButtonDropdown.type';
import { SMenu, SMenuItem } from './MobileDropdown.styles';

const MobileDropdown = ({
  onClickItem,
  items,
  buttonTitle,
  buttonIcon,
  buttonType = EButtonTypes.Primary,
  children,
  iconColor = Colors.Black,
  buttonDownIcon,
  buttonColor,
  buttonSize,
  disabled,
  buttonClassName,
}: TButtonDropdownProps) => {
  const [modalIsVisible, setModalIsVisible] = useState(false);

  return (
    <>
      {children || (
        <Button
          disabled={disabled}
          onClick={() => setModalIsVisible(true)}
          type={buttonType}
          className={buttonClassName}
          {...(buttonSize && { size: buttonSize })}
          {...(buttonColor && { color: buttonColor })}
          {...(buttonIcon && { icon: buttonIcon })}
        >
          {buttonTitle || 'Title'}{' '}
          {buttonDownIcon && (
            <Icon
              icon={TIconNames.ARROW_DROPDOWN}
              color={iconColor}
              size={16}
              className="ml-3 mr-0"
            />
          )}
        </Button>
      )}
      <Modal
        centered
        bodyStyle={{ padding: 0 }}
        closable={false}
        footer={null}
        visible={modalIsVisible}
        onCancel={() => setModalIsVisible(false)}
        size={ModalWidthEnum.Small}
        destroyOnClose
      >
        <SMenu>
          {items.map((item) => (
            <SMenuItem
              disabled={item.disabled}
              key={item.key}
              className="menu-item-ticket-filter"
              onClick={(e: { domEvent: { stopPropagation: () => void } }) => {
                e.domEvent.stopPropagation();
                onClickItem(item.key);
                setModalIsVisible(false);
              }}
            >
              <div>
                {item.icon && (
                  <Icon
                    icon={item.icon}
                    color={iconColor}
                    size={20}
                    className="mr-2"
                  />
                )}
                {item.name}
              </div>
              {item.selected && (
                <Icon
                  icon={TIconNames.CHECK_ROUNDED}
                  size={16}
                  color={Colors.SuccessColor}
                />
              )}
            </SMenuItem>
          ))}
        </SMenu>
      </Modal>
    </>
  );
};

export default MobileDropdown;
