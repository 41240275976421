import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { FilePreview } from '../../FilePreview';
import { Modal } from '../../Modal';
import { ModalWidthEnum } from '../../Modal/Modal.type';
import { TFileData } from '../File.type';
import { SPreviewFileModalContent } from './PreviewFileModal.styles';
import { TPreviewFileModalProps } from './PreviewFileModal.types';

const PreviewFileModal = forwardRef(
  ({ textFileContent }: TPreviewFileModalProps, ref) => {
    const [open, setOpen] = useState(false);
    const [file, setFile] = useState<TFileData>();

    useImperativeHandle(ref, () => ({
      open: (previewFile: TFileData) => {
        setOpen(true);
        setFile(previewFile);
      },
    }));

    if (!file) {
      return null;
    }

    return (
      <Modal
        title="Preview file"
        size={ModalWidthEnum.Large}
        visible={open}
        destroyOnClose
        cancelText="Close"
        onCancel={() => setOpen(false)}
        okButtonProps={{ style: { display: 'none' } }}
      >
        <SPreviewFileModalContent>
          <FilePreview
            fileUrl={file.url}
            extension={file.name}
            textFileContent={textFileContent}
          />
        </SPreviewFileModalContent>
      </Modal>
    );
  }
);

export default PreviewFileModal;
