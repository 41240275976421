import React, { FC } from 'react';
import { Colors, FontWeights } from 'core/CssVariables';
import { TInfoBlockProps } from './InfoBlock.types';
import { SecondaryText } from '../Typography';
import { SInfoBlockWrapper } from './InfoBlock.styles';

const InfoBlock: FC<TInfoBlockProps> = ({
  label,
  content,
  color = 'white',
  centered = false,
  width,
  height,
  fontWeight = FontWeights.Bold,
}) => {
  return (
    <SInfoBlockWrapper
      color={color}
      $centered={centered}
      $width={width}
      $height={height}
    >
      {label}
      <SecondaryText
        className="mt-1"
        fontLevel={5}
        color={Colors.DarkGrey}
        fontWeight={fontWeight}
      >
        {content}
      </SecondaryText>
    </SInfoBlockWrapper>
  );
};

export { InfoBlock };
