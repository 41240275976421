import React, { FC } from 'react';
import { Colors, FontWeights } from 'core/CssVariables';
import { Button } from '../../Button';
import { Text } from '../../Typography';
import { TUploaderContentProps } from '../Uploader.type';

export const UploaderContent: FC<TUploaderContentProps> = ({
  title,
  invalid,
  dropFileTitle,
}) => {
  return (
    <Text
      fontLevel={5}
      fontWeight={FontWeights.Light}
      color={invalid ? Colors.ErrorColor : Colors.DarkGrey}
      className="mb-2"
    >
      {invalid && title}
      {!invalid && (
        <>
          <div>
            Browse or drag and drop{' '}
            <Button type="text" className="mb-0 mt-2">
              {`${dropFileTitle || 'documents'}`}
            </Button>{' '}
            here
          </div>
          {title && (
            <Text
              fontWeight={FontWeights.Light}
              color={Colors.LightGrey}
              fontLevel={6}
            >
              {title}
            </Text>
          )}
        </>
      )}
    </Text>
  );
};
