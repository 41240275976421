export enum EVerificationCodeInputType {
  LINED = 'lined',
  DEFAULT = '',
}

export type TVerificationCodeProps = {
  onChange?: (value: string) => void;
  regExp?: RegExp;
  codeLength?: number;
  className?: string;
  inputType?: EVerificationCodeInputType;
};
