import Carousel from 'antd/es/carousel';
import styled from 'styled-components';
import { Colors } from 'core/CssVariables';

export const SCarousel = styled(Carousel)`
  & {
    .slick-dots {
      &.slick-dots {
        &-bottom {
          bottom: 0;
        }
        &-top {
          top: 0;
        }
        &-top,
        &-bottom,
        &-right,
        &-left {
          li {
            margin-right: 2px;
            margin-left: 2px;
            &.slick-active {
              width: 16px;
              button {
                background-color: ${Colors.PrimaryColor};
                width: 8px;
                height: 8px;
              }
            }
            button {
              background-color: ${Colors.LightGrey};
              width: 8px;
              height: 8px;
              opacity: 1;
              border-radius: 50%;
            }
          }
        }
      }
    }
  }
`;
