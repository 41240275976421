import React, { FC } from 'react';
import { SCarousel } from './Carousel.styles';
import { TCarouselProps } from './Carousel.types';

const Carousel: FC<TCarouselProps> = ({ children, className, ...props }) => {
  return (
    <SCarousel className={className} {...props}>
      {children}
    </SCarousel>
  );
};

export { Carousel };
