import React, { FC } from 'react';
import { Colors } from 'core/CssVariables';
import { Icon } from '../../Icon';
import { Spinner } from '../../Spinner';
import { ToolTip } from '../../ToolTip';
import { TIconNames } from '../../Icon/Icon.type';
import NonEditablePhoto from '../NonEditablePhoto';
import EditablePhotoForm from './components/EditablePhotoForm';
import SEditableText from '../EditableText/EditableText.style';
import { TEditablePhotoProps } from '../EditableFields.type';

export const EditablePhoto: FC<TEditablePhotoProps> = (props) => {
  const {
    onEdit,
    isLoading,
    selected,
    photoUrl,
    error,
    minMedia,
    height,
    width,
    canEdit,
    uploadFile,
    uploadedImage,
    preview,
      type = 'circle'
  } = props;

  if (canEdit) {
    return (
      <SEditableText
        onClick={(e) => e.stopPropagation()}
        minMedia={minMedia}
        isEditable={false}
        selected={selected}
        className="mr-2 pr-6 w-100"
      >
        <EditablePhotoForm
          type={type}
          onEdit={onEdit}
          photoUrl={photoUrl}
          height={height}
          width={width}
          isLoading={isLoading}
          uploadFile={uploadFile}
          preview={preview}
          uploadedImage={uploadedImage}
        />
        {isLoading && <Spinner size="default" centered />}
        {error && (
          <ToolTip title={error} placement="topRight" className="edit-error">
            <span>
              <Icon
                color={Colors.ErrorColor}
                icon={TIconNames.INFO}
                size={20}
              />
            </span>
          </ToolTip>
        )}
      </SEditableText>
    );
  }

  return <NonEditablePhoto photoUrl={photoUrl} height={height} width={width} />;
};

export default EditablePhoto;
