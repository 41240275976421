import styled from 'styled-components';
import { Colors, Screens } from 'core/CssVariables';

export const SVerificationInputContainer = styled.div`
  &&& {
    display: flex;
    justify-content: center;

    .verification-input {
      height: 46px;
      width: 40px;
      text-align: center;

      :not(:last-child) {
        margin-right: 16px;

        @media (max-width: ${Screens.ScreensXS}) {
          margin-right: 8px;
        }
      }
    }

    .lined {
      background: none;
      border: none;
      border-bottom: 2px solid ${Colors.WarningColor};
      border-radius: unset;
    }
  }
`;
