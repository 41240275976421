import React, { FC } from 'react';
import { SStep, MyCustomStep } from './Stepper.styles';
import { TStepItem, TStepperProps } from './Stepper.types';
import { getCustomDot } from './Stepper.data';

const Stepper: FC<TStepperProps> = ({
  stepsData,
  currentStep,
  children,
  ...rest
}) => {
  return (
    <MyCustomStep
      {...rest}
      current={currentStep}
      progressDot={(index, stepData) => getCustomDot(index, stepData)}
    >
      {stepsData?.map((item: TStepItem) => (
        <SStep
          key={item.title}
          title={item.title}
          description={item.description}
        />
      ))}
      {children}
    </MyCustomStep>
  );
};

export { Stepper };
