import React, { FC } from 'react';
import { Button } from '../../Button';
import { CardSection } from '../../CardSection';
import { TStepperContentProps } from './StepperContent.types';

const StepperContent: FC<TStepperContentProps> = ({
  onSubmit,
  onNextClick,
  onPreviousClick,
  loading,
  stepsData,
  submitButtonText = 'Submit',
  currentStep = 0,
}) => {
  const previousClick = () => {
    onPreviousClick();
  };

  const nextClick = () => {
    onNextClick();
  };

  return (
    <>
      <CardSection color="grey">{stepsData[currentStep].content}</CardSection>
      <div className="mt-6">
        {currentStep < stepsData.length - 1 && (
          <Button
            type="primary"
            onClick={nextClick}
            className="button"
            loading={loading}
          >
            Next
          </Button>
        )}
        {currentStep > 0 && (
          <Button
            type="primary"
            className="mr-3 button"
            onClick={previousClick}
            loading={loading}
          >
            Previous
          </Button>
        )}
        {currentStep === stepsData.length - 1 && (
          <Button
            type="primary"
            onClick={onSubmit}
            loading={loading}
            className="button"
          >
            {submitButtonText}
          </Button>
        )}
      </div>
    </>
  );
};

export default StepperContent;
