import React, { FC } from 'react';
import { TCardSectionProps } from './CardSection.type';
import { SCardSection } from './CardSection.style';

const CardSection: FC<TCardSectionProps> = ({
  color,
  justify,
  height,
  minHeight,
  active,
  className,
  children,
  ...props
}) => {
  return (
    <SCardSection
      $active={active}
      color={color}
      justify={justify}
      height={height}
      $minHeight={minHeight}
      className={className}
      {...props}
    >
      {children}
    </SCardSection>
  );
};

export { CardSection };
