import DatePicker from 'antd/es/date-picker';
import { Colors } from 'core/CssVariables';
import styled, { css } from 'styled-components';
import { TDatePickerProps, TDateRangePickerProps } from './DatePicker.type';

const { RangePicker } = DatePicker;

const cssPicker = css`
  &.ant-picker {
    width: 100%;
    border-color: ${Colors.PrimaryColor};
    &.ant-picker-status-error {
      border-color: ${Colors.ErrorColor};
    }
    .ant-picker-input {
      input {
        color: ${Colors.Black};
        &[disabled] {
          color: ${Colors.DisabledColor};
        }
      }
      input::placeholder {
        color: ${Colors.LightGrey};
      }
    }
    .anticon {
      color: ${Colors.Black};
    }
  }
`;

// @ts-ignore
const SDateRangePicker = styled(RangePicker)<TDateRangePickerProps>`
  ${cssPicker}
`;
// @ts-ignore
const SDatePicker = styled(DatePicker)<TDatePickerProps>`
  ${cssPicker}
`;

export { SDateRangePicker, SDatePicker };
