import styled from 'styled-components';
import Tabs from 'antd/es/tabs';
import { BorderRadius, Colors, Spacing } from 'core/CssVariables';
import { TTabStyledProps } from './Tab.types';

export const { TabPane } = Tabs;

export const STabs = styled(Tabs)<TTabStyledProps>`
  && {
    &.ant-tabs {
      & .ant-tabs-nav {
        background-color: ${Colors.Transparent};

        :before {
          border-bottom: none;
        }
      }

      & .ant-tabs-nav-list {
        & .ant-tabs-tab {
          &:first-child {
            margin-left: 0px;
          }
          padding: ${Spacing.SpacingXS}px ${Spacing.SpacingSM}px;
          margin: 0 16px;
          background-color: ${Colors.Transparent};
          border: 1px solid ${Colors.DisabledColor};
          border-radius: ${BorderRadius.RadiusXS}px;

          & .ant-tabs-tab-btn {
            &,
            &:focus,
            &:active,
            & .ant-typography {
              color: ${Colors.DarkGrey};
            }
            & svg {
              path {
                fill: ${Colors.DarkGrey};
              }
            }
          }

          &.ant-tabs-tab-active {
            background-color: ${Colors.PrimaryColor};
            border: none;

            & .ant-tabs-tab-btn {
              &,
              &:focus,
              &:active,
              & .ant-typography {
                color: ${Colors.Black};
              }
              & svg {
                path {
                  fill: ${Colors.Black};
                }
              }
            }
          }

          &:hover {
            color: ${Colors.Black};
          }
        }
      }
    }
  }
`;
