import styled, { css } from 'styled-components';
import { Screens } from 'core/CssVariables';
import Image from 'antd/es/image';
import { TSImagePreviewProps } from './ImagePreview.types';

export const SImage = styled(Image)<TSImagePreviewProps>`
  && {
    height: ${({ $height }) => $height || 145}px;
    width: ${({ $width }) => $width || 193}px;
    border-radius: 8px;
    object-fit: cover;
    ${({ screenSize }) => css`
      @media (max-width: ${screenSize || Screens.ScreensMD}) {
        .ant-image {
          width: 100%;
          height: auto;
        }
      }
    `}
  }
`;
