import React from 'react';
import { Colors } from 'core/CssVariables';
import { Button } from '../../Button';
import { EButtonTypes } from '../../Button/Button.type';
import { Menu, MenuItem } from '../../Menu';
import { Icon } from '../../Icon';
import { TIconNames } from '../../Icon/Icon.type';
import { Dropdown } from '../../Dropdown';
import { TButtonDropdownProps } from '../ButtonDropdown.type';

const DesktopDropdown = ({
  onClickItem,
  items,
  trigger,
  buttonTitle,
  buttonIcon,
  buttonType = EButtonTypes.Primary,
  children,
  iconColor = Colors.Black,
  buttonDownIcon,
  buttonColor,
  buttonSize,
  outlined,
  disabled,
  buttonClassName,
}: TButtonDropdownProps) => {
  return (
    <Dropdown
      disabled={disabled}
      className="customScroll"
      trigger={trigger}
      overlay={
        <Menu>
          {items.map((item) => (
            <MenuItem
              disabled={item.disabled}
              key={item.key}
              className="menu-item-ticket-filter"
              onClick={(e) => {
                // TODO move to function and add type
                e.domEvent.stopPropagation();
                onClickItem(item.key);
              }}
            >
              <div>
                {item.icon && (
                  <Icon
                    icon={item.icon}
                    color={iconColor}
                    size={20}
                    className="mr-2"
                  />
                )}
                {item.name}
              </div>
              {item.selected && (
                <Icon
                  icon={TIconNames.CHECK_ROUNDED}
                  size={16}
                  color={Colors.SuccessColor}
                />
              )}
            </MenuItem>
          ))}
        </Menu>
      }
    >
      {children || (
        <Button
          disabled={disabled}
          outlined={outlined}
          type={buttonType}
          className={buttonClassName}
          {...(buttonSize && { size: buttonSize })}
          {...(buttonColor && { color: buttonColor })}
          {...(buttonIcon && { icon: buttonIcon })}
        >
          {buttonTitle || 'Title'}{' '}
          {buttonDownIcon && (
            <Icon
              icon={TIconNames.ARROW_DROPDOWN}
              color={iconColor}
              size={16}
              className="ml-3 mr-0"
            />
          )}
        </Button>
      )}
    </Dropdown>
  );
};

export default DesktopDropdown;
