import { Colors } from 'core/CssVariables';

export enum TIconNames {
  QUESTION_MARK = 'question-mark',
  PLAY_CIRCLE = 'play-circle',
  COMMENT = 'comment',
  HOUSE = 'house',
  PEN_ICON = 'pen-icon',
  APPLICATION = 'application',
  CONVERT_ICON = 'convert-icon',
  GALLERY = 'gallery',
  ERROR_FILLED = 'error-filled',
  TOOLTIP_ICON = 'tooltip-icon',
  UNDO = 'undo',
  FILTER = 'filter',
  TOOLTIP = 'tooltip',
  CHECK_ROUNDED_GHOST = 'check-rounded-ghost',
  CLOSE = 'close-rounded-ghost',
  PAYMENT = 'payment',
  SCHOOL = 'school',
  PLUS_CIRCLE = 'plus-circle',
  EMAIL = 'email',
  PHONE = 'phone',
  ENVELOPE = 'envelope',
  MOBILE_PHONE = 'mobile-phone',
  FILE_ICON = 'file-icon',
  FILE = 'file',
  SHIELD = 'shield',
  SHIELD_CLOSE = 'shield-close',
  FAX = 'fax',
  DOC = 'doc',
  DOWNLOAD = 'download',
  DOWNLOAD_ARROW = 'download-arrow',
  CALENDAR_FILLED = 'calendar-filled',
  CALENDAR = 'calendar',
  CALENDAR_WITH_DATES = 'calendar-with-dates',
  VERIFIED = 'verified',
  CAKE = 'cake',
  SCALES = 'scales',
  GENDER = 'gender',
  PETS_POW = 'pets_pow',
  PET_POW_QUOTE = 'pet-pow-quote',
  PETS_HOME = 'pet-home',
  FACT_CHECK = 'fact_check',
  CHECK_CIRCLE_FILLED_ICON = 'check-circle-filled-icon',
  CHECK_FILLED = 'check-filled',
  CHECK_POLYGON = 'check-polygon',
  CHECK_POLYGON_GHOST = 'check-polygon-ghost',
  CLOSE_POLYGON = 'close-polygon',
  CLOSE_POLYGON_GHOST = 'close-polygon-ghost',
  BUILDINGS = 'buildings',
  BUILDING_PERSON = 'building-person',
  KEY_SETTINGS = 'key-setting',
  OPEN_DOOR = 'open-door',
  PALETTE = 'palette',
  GPS = 'gps',
  CAMERA = 'camera',
  FEMALE = 'female',
  MALE = 'male',
  CLOSE_CIRCLE = 'close-circle',
  LOGO_WITH_TEXT = 'logo-with-text',
  LOGO_WITH_TEXT_DARK = 'logo-with-text-dark',
  CALENDAR_ICON = 'calendar-icon',
  ARROW_LEFT_WITH_TALE = 'arrow-left-with-tale',
  ARROW_FOR_SCHEMA = 'arrow-for-schema',
  ARROW_DROPDOWN = 'arrow-dropdown',
  ARROW_RIGHT = 'arrow-right',
  ARROW_LEFT = 'arrow-left',
  ARROW_FILLED = 'arrow-filled',
  TRIANGLE_ARROW_FILLED = 'triangle-arrow-filled',
  BLOCK = 'block',
  CHECK_ROUNDED = 'check-rounded',
  CHECK_OUTLINED = 'check-outlined',
  WARNING_FILLED = 'warning-filled',
  WARNING_OUTLINED = 'warning-outlined',
  CLOCK = 'clock',
  THREE_DOTS_FILLED_CIRCLE = 'three-dots-filled-circle',
  THREE_DOTS_GHOST = 'three-dots-ghost',
  CLOCK_FILLED = 'clock-filled',
  DASHBOARD = 'dashboard',
  DELETE = 'delete',
  DONE = 'done',
  DRAG = 'drag',
  EDIT = 'edit',
  EXPAND = 'expand',
  EYE_HIDE = 'eye-hide',
  EYE_SHOW = 'eye-show',
  ENTER = 'enter',
  GROUP = 'group',
  HIGHLIGHT_OFF = 'highlight-off',
  INFO = 'info',
  LAPTOP = 'laptop',
  LOCK = 'lock-icon',
  MENU = 'menu',
  NOTIFICATION = 'notification',
  NOTIFICATION_OFF = 'notification-off',
  OPEN_WITH = 'open-with',
  PEOPLE = 'people',
  PERSON = 'person',
  PERSON_FILLED = 'person-filled',
  PERSON_ADD = 'person-add',
  PERSON_REMOVE = 'person-remove',
  PERSON_REMOVE_FILLED = 'person-remove-filled',
  PLAY_VIDEO = 'play-video',
  PLUS = 'plus',
  REPORT = 'report',
  SEARCH = 'search',
  SETTINGS = 'settings-filled',
  TASK = 'task',
  TICKET = 'ticket',
  UNREAD = 'unread',
  UPLOAD = 'upload',
  FILTER_TABLE = 'filter-table',
  COLLAPSE = 'collapse',
  ASSIGNMENT = 'assignment',
  LEFT_ARROW = 'left-arrow',
  RIGHT_ARROW = 'right-arrow',
  BUSINESS_BAG = 'business_bag',
  PROFILE = 'profile',
  PROFILE_ICON = 'profile-icon',
  CASE = 'case',
  PET_POW = 'pets_pow',
  LOGOUT = 'logout',
  SCHEDULE = 'schedule',
  LOCATION = 'location',
  WEBSITE = 'website',
  EARTH = 'earth',
  SEGMENT = 'segment',
  CONTACT_PHONE = 'contact_phone',
  OVERDUE_TICKET = 'overdue_ticket',
  OVERDUE_TASK = 'overdue_task',
  SLA = 'sla',
  NOTE_ICON = 'note-icon',
  RESTART_ICON = 'restart-icon',
  URGENCY = 'urgency',
  MENU_DOT = 'menu_dot',
  OVERDUE = 'overdue',
  IMPORT = 'import-fill',
  CLOSE_ROUND = 'close-fill',
  LINE_SM = 'line-sm',
  CLOSE_ROUND_LIGHT = 'close-filled-white',
  HORIZONTAL_LINE_CIRCLE_FILLED = 'horizontal-line-circle-filled',
  COPY = 'copy',
  DUPLICATE = 'duplicate',
  VIDEO = 'video-icon',
  PET_TARGET = 'pet-target',
  VISIT_ANIMAL = 'visit-animal',
  DELETE_OUTLINED = 'delete-outlined',
  MOVE_OUT = 'move-out',
  IMPERSONATE = 'impersonate',
}

export type TIconProps = {
  isLoading?: boolean;
  color?: Colors;
  size?: number;
  icon: string;
  className?: string;
  onClick?: (e?: MouseEvent) => void;
  dataTestId?: string;
};
