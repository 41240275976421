import styled from 'styled-components';
import DocViewer from '@cyntler/react-doc-viewer';

export const SDocViewer = styled(DocViewer)`
  & {
    height: 100%;
    #proxy-renderer {
      overflow-y: hidden;
    }
  }
`;
