import React, { FC } from 'react';
import { TTabProps } from './Tab.types';
import { STabs, TabPane } from './Tab.styles';

const Tab: FC<TTabProps> = ({ tabData, onChange, type = 'card', ...props }) => {
  return (
    <STabs onChange={onChange} type={type} tabBarGutter={0} {...props}>
      {tabData.map((item) => (
        <TabPane tab={item.tab} key={item.key}>
          {item.content}
        </TabPane>
      ))}
    </STabs>
  );
};

export { Tab };
