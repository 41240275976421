import React, { useState, useRef, KeyboardEvent, FC } from 'react';
import { InputRef } from 'antd/es/input';
import { TextField } from '../TextField';
import { EInputTypes } from '../TextField/TextField.type';
import { SVerificationInputContainer } from './VerificationCode.style';
import {
  EVerificationCodeInputType,
  TVerificationCodeProps,
} from './VerificationCode.type';

export const VerificationCode: FC<TVerificationCodeProps> = ({
  onChange = () => {
    /* do nothing */
  },
  regExp = /[^0-9]/,
  codeLength = 6,
  className,
  inputType = EVerificationCodeInputType.DEFAULT,
}: TVerificationCodeProps) => {
  const [code, setCode] = useState(Array(codeLength).fill(''));
  const inputsRef = useRef<InputRef[]>([]);

  const handleChange = (value: string, index: number) => {
    if (regExp.test(value) || !value) {
      return;
    }
    const newCode = [...code];
    newCode[index] = value || '';
    setCode(newCode);
    if (index !== codeLength - 1 && inputsRef.current[index + 1]) {
      inputsRef.current[index + 1].focus();
    }

    onChange(newCode.join(''));
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>, index: number) => {
    const canGoLeft = index !== 0 && inputsRef.current[index - 1];
    const canGoRight = index !== codeLength - 1 && inputsRef.current[index + 1];

    if (e.keyCode === 37 && canGoLeft) {
      inputsRef.current[index - 1].focus();
    }

    if (e.keyCode === 39 && canGoRight) {
      inputsRef.current[index + 1].focus();
    }

    if (e.keyCode === 8 || e.keyCode === 46) {
      e.preventDefault();
      const newCode = [...code];
      newCode[index] = '';
      setCode(newCode);
      onChange(newCode.join(''));

      if (e.keyCode === 8 && canGoLeft) {
        inputsRef.current[index - 1].focus();
      }
      if (e.keyCode === 46 && canGoRight) {
        inputsRef.current[index + 1].focus();
      }
    }
  };

  const handlePast = (val: string) => {
    const trimmedValue = val.trim();

    if (regExp.test(trimmedValue)) {
      return;
    }

    const newValue =
      trimmedValue.length > codeLength
        ? trimmedValue.slice(0, codeLength)
        : trimmedValue;
    const valueArray =
      newValue.length < codeLength
        ? newValue
            .split('')
            .concat(new Array(codeLength - newValue.length).fill(''))
        : newValue.split('');
    setCode(valueArray);
    onChange(newValue);

    if (inputsRef.current[newValue.length - 1]) {
      inputsRef.current[newValue.length - 1].focus();
    }
  };

  return (
    <SVerificationInputContainer className={className}>
      {code.map((val, index) => (
        <TextField
          maxLength={1}
          className={`verification-input ${inputType}`}
          onPaste={(value) => handlePast(value.clipboardData.getData('Text'))}
          color={EInputTypes.SecondaryOutlined}
          key={`code-${index + 1}`}
          value={val}
          onChange={(e) => handleChange(e.target.value, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          ref={(ref) => {
            if (ref) {
              inputsRef.current[index] = ref;
            }
          }}
        />
      ))}
    </SVerificationInputContainer>
  );
};

export default VerificationCode;
