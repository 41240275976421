import React, { FC } from 'react';
import { TImagePreviewProps } from './ImagePreview.types';
import { SImage } from './ImagePreview.styles';

const ImagePreview: FC<TImagePreviewProps> = ({
  width,
  height,
  src,
  ...props
}) => {
  return <SImage $width={width} $height={height} src={src} {...props} />;
};

export { ImagePreview };
