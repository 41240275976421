import Tag from 'antd/es/tag';
import styled, { css } from 'styled-components';
import {
  BorderRadius,
  Colors,
  FontSizes,
  FontWeights,
  Spacing,
} from 'core/CssVariables';
import { EPositionedTagTypes, TTagStyledProps } from './Tag.type';

const tagStylesByType = ({
  $type,
  color,
  $borderColor,
  $textColor,
  $fontWeight,
  $padding,
}: TTagStyledProps) => {
  if ($type === 'primary') {
    return css`
      && {
        background-color: ${color || Colors.PrimaryColor};
        color: ${$textColor || Colors.Black};
      }
    `;
  }
  if ($type === 'outlined') {
    return css`
      && {
        color: ${$textColor || Colors.Black};
        font-weight: ${$fontWeight || FontWeights.Regular};
        font-size: ${FontSizes.FontSM}px;
        border: 1px solid ${$borderColor || Colors.Black};
        padding: ${$padding ||
        `${Spacing.SpacingXXS}px ${Spacing.SpacingXS}px`};
        border-radius: ${BorderRadius.RadiusMD}px;
      }
    `;
  }
  return '';
};

const tagStylesByPositionType = ($positionedType?: EPositionedTagTypes) => {
  if ($positionedType === 'corner-right') {
    return css`
      && {
        border-radius: 0 ${BorderRadius.RadiusXS}px;
      }
    `;
  }
  if ($positionedType === 'corner-left') {
    return css`
      && {
        border-radius: ${BorderRadius.RadiusXS}px 0;
      }
    `;
  }
  if ($positionedType === 'corner-top-left') {
    return css`
      && {
        border-radius: ${BorderRadius.RadiusXS}px 0 0 0;
      }
    `;
  }
  if ($positionedType === 'top') {
    return css`
      && {
        border-radius: 0 0 ${BorderRadius.RadiusXS}px ${BorderRadius.RadiusXS}px;
      }
    `;
  }
  if ($positionedType === 'rounded') {
    return css`
      && {
        border-radius: ${BorderRadius.RadiusXS}px;
      }
    `;
  }
  return '';
};

const mainTagStyles = ({
  $fontWeight,
  $padding,
  color,
}: TTagStyledProps) => css`
  && {
    display: inline-flex;
    align-items: center;
    vertical-align: middle;
    width: fit-content;
    font-weight: ${$fontWeight || FontWeights.Bold};
    line-height: normal;
    padding: ${$padding || `${Spacing.SpacingXXS}px ${Spacing.SpacingSM}px`};
    border: 0;
    margin: 0;
    background-color: ${color || 'transparent'};
    color: ${Colors.White};
    border-radius: ${BorderRadius.RadiusXXXS}px;
    font-size: ${FontSizes.FontSM}px;

    .icon {
      margin-right: 2px;
      margin-left: 7px;
    }

    .ant-tag-close-icon {
      font-size: ${FontSizes.FontXS}px;

      .icon {
        margin-right: 0;
        vertical-align: text-top;
      }
    }
  }
`;

const STag = styled(Tag)<TTagStyledProps>`
  && {
    ${(props) => mainTagStyles(props)};
    ${(props) => tagStylesByType(props)};
    ${({ $positionedType }) => tagStylesByPositionType($positionedType)};
  }
`;
export default STag;
