import React, {
  ChangeEvent,
  FC,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Colors } from 'core/CssVariables';
import { ImagePreview } from '../ImagePreview';
import { Icon } from '../Icon';
import { Text } from '../Typography';
import { Spinner } from '../Spinner';
import { TIconNames } from '../Icon/Icon.type';
import SUploadWrapper from './ImageUploader.style';
import { EErrorMessage, TImageUploaderProps } from './ImageUploader.type';

export const ImageUploader: FC<TImageUploaderProps> = ({
  onChange = () => {
    /* do nothing */
  },
  uploadFile = () => {
    /* do nothing */
  },
  preview = false,
  uploadedImage,
  url = '',
  width,
  height,
  disabled = false,
  isLoading,
  id,
}) => {
  const uniqueId = useMemo(() => Date.now().toString(), []);
  const uploadedImageRef = useRef<HTMLImageElement>(null);
  const imageUploader = useRef(null);
  const [error, setError] = useState('');
  const [showIcon, setShowIcon] = useState(!!url);

  useEffect(() => {
    if (!uploadedImage) {
      return;
    }
    onChange(uploadedImage);
  }, [uploadedImage]);

  const validateFile = (file: File) => {
    if (file?.size > 10485760) {
      setError(EErrorMessage.SIZE_ERROR);
      return false;
    }

    if (!file?.type.match('image/jpeg|image/png')) {
      setError(EErrorMessage.FORMAT_ERROR);
      return false;
    }
    setError('');
    return true;
  };

  const handleImageUpload = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];

      if (file && validateFile(file)) {
        const reader = new FileReader();
        const formData = new FormData();

        const { current } = uploadedImageRef;

        if (current) {
          reader.onload = (event) => {
            if (
              event?.target?.result &&
              typeof event?.target?.result === 'string'
            ) {
              current.src = event.target.result;
              setShowIcon(true);
            }
          };
          reader.readAsDataURL(file);

          formData.append('file', file);
          uploadFile(formData);
        }
      }
    }
  };

  return (
    <SUploadWrapper width={width} height={height} id={id}>
      <div className="upload-wrapper">
        <label
          htmlFor={uniqueId}
          className={`upload-btn pointer ${!showIcon ? 'show-border' : ''}`}
        >
          <input
            type="file"
            accept=".jpeg, .jpg, .png"
            name="image"
            id={uniqueId}
            onChange={handleImageUpload}
            ref={imageUploader}
            disabled={disabled}
          />

          {isLoading && <Spinner size="default" centered />}
          {!url && !isLoading && (
            <Icon icon={TIconNames.CAMERA} color={Colors.LightGrey} size={24} />
          )}
          <img
            className="upload-image"
            ref={uploadedImageRef}
            {...(url && { src: url })}
            alt=""
          />
          {showIcon && (
            <div className="camera-icon">
              <Icon icon={TIconNames.CAMERA} size={14} />
            </div>
          )}
        </label>
      </div>
      {url && preview && (
        <div className="preview-wrapper">
          <ImagePreview
            className="upload-image"
            width={width}
            height={height}
            preview={preview}
            src={url}
          />
        </div>
      )}

      {error && (
        <Text
          fontLevel={6}
          className="uploader-error"
          color={Colors.ErrorColor}
        >
          {error}
        </Text>
      )}
    </SUploadWrapper>
  );
};

export default ImageUploader;
