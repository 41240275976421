import styled from 'styled-components';
import Collapse from 'antd/es/collapse';
import { Colors } from 'core/CssVariables';
import {
  CollapseInterface,
  TCollapseBgColor,
  TSCollapseProps,
} from './Collapse.types';

const { Panel } = Collapse;

export const colorsMapper: { [key in TCollapseBgColor]: Colors } = {
  white: Colors.White,
  grey: Colors.BackgroundColor,
  danger: Colors.ErrorOpacity,
};

const SCollapse = styled<CollapseInterface>(Collapse)<TSCollapseProps>`
  &.ant-collapse {
    background-color: ${({ $color }) => colorsMapper[$color]};
    border: 1px solid ${Colors.DisabledColor};
    .ant-collapse-item {
      border: none;
      & .ant-collapse-header {
        padding: 0;
        .ant-collapse-expand-icon {
          svg {
            &.active {
              transform: rotate(0);
            }
            transform: rotate(180deg);
          }
        }
      }
      &.ant-collapse-no-arrow {
        .ant-collapse-header {
          padding: 0;
        }
      }
      .ant-collapse-content {
        background-color: ${Colors.Transparent};
        border: none;
        .ant-collapse-content-box {
          padding: 12px;
        }
      }
      &.no-body-content {
        .ant-collapse-content {
          .ant-collapse-content-box {
            padding: 0;
          }
        }
        .ant-collapse-header {
          cursor: auto;
        }
      }
    }
  }
`;

const SPanel = styled(Panel)`
  & {
  }
`;

export { SCollapse, SPanel };
