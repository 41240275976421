import styled from 'styled-components';
import { Text } from '../../Typography';
import { NonEditableText } from '../NonEditableText';

export const SNonEditableText = styled(NonEditableText)`
  && {
    .ant-typography {
      white-space: pre-wrap;
    }
  }
`;

export const SText = styled(Text)`
  &&& {
    &.ant-typography {
      padding: 4px 8px;
      white-space: pre-wrap;
      min-height: 52px;
      align-items: flex-start;
    }
  }
`;
