import { ComponentType } from 'react';
import styled from 'styled-components';
import Steps, { StepsProps } from 'antd/es/steps';
import { Colors, FontSizes, FontWeights } from 'core/CssVariables';

const { Step } = Steps;

export const MyCustomStep = styled<ComponentType<StepsProps>>(
  Steps
)<StepsProps>`
  &.ant-steps {
    .ant-steps-item {
      .ant-steps-item {
        &-tail {
          &::after {
            color: ${Colors.Black};
            margin: 22px 0 0 18px;
          }
        }
        &-icon {
          width: 20px;
          height: 20px;
          margin-top: 30px;
          .ant-steps-icon-dot {
            left: 0px;
          }
        }
      }
      .ant-steps-item-container {
        margin-bottom: 36px;
      }
      .ant-steps-item-content {
        .ant-steps-item-title {
          color: ${Colors.DarkGrey};
          font-size: ${FontSizes.FontSM}px;
          font-weight: ${FontWeights.Medium};
        }
        .ant-steps-item-description {
          color: ${Colors.Black};
          font-size: ${FontSizes.FontMD}px;
          font-weight: ${FontWeights.Bold};
        }
      }
    }
  }
`;

export const SProgressDotCircle = styled.div<{ $color: Colors }>`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${({ $color }) => $color || Colors.PrimaryColor};
`;

export const SStep = styled(Step)``;
