export type TFileViewerProps = {
  fileUrl: string;
  extension: string;
  textFileContent?: string;
};

export enum EFileTypes {
  // Define the supported file types here
  bmp = 'bmp',
  csv = 'csv',
  odt = 'odt',
  doc = 'doc',
  docx = 'docx',
  gif = 'gif',
  htm = 'htm',
  html = 'html',
  jpg = 'jpg',
  jpeg = 'jpeg',
  pdf = 'pdf',
  png = 'png',
  ppt = 'ppt',
  pptx = 'pptx',
  tiff = 'tiff',
  txt = 'txt',
  xls = 'xls',
  xlsx = 'xlsx',
}
